import VueAxios from 'vue-axios'
import axios from 'axios'
import Vue from 'vue'
import router from '@/router'

const ApiService = {
    init() {
        Vue.use(VueAxios, axios)
        Vue.axios.defaults.baseURL = process.env.VUE_APP_API_URL
        Vue.axios.defaults.headers.common['Content-Type'] = 'application/json'
        Vue.axios.defaults.withCredentials = true

        Vue.axios.interceptors.response.use(undefined, async (err) => {
            let status = parseInt(err.response.status)
            if (status === 401) {
                await router.push({name: 'logout'})
            }
            throw err
        })

        if (localStorage.getItem('token') == null) {
            return
        }

        Vue.axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('token')
    },


    async get(endpoint, id = '', params) {
        let url = id !== '' ? `${endpoint}/${id}` : `${endpoint}`
        let response = await Vue.axios.get(url, {withCredentials: true, params: params})
        return response.data
    },

    async post(endpoint, data) {
        let response = await Vue.axios.post(`${endpoint}`, data)
        return response.data
    },

    async put(endpoint, data) {
        let id = data.id ? data.id : ''
        delete (data.id)
        let response = await Vue.axios.put(`${endpoint}/${id}`, data)
        return response.data
    },

    async patch(endpoint, data) {
        let id = data.id ? data.id : ''
        delete (data.id)
        let response = await Vue.axios.patch(`${endpoint}/${id}`, data)
        return response.data
    },

    async delete(endpoint, id) {
        let response = await Vue.axios.delete(`${endpoint}/${id}`)
        return response.data
    }
}

export default ApiService
