<template>
  <md-app id="app">
    <md-app-toolbar class="md-dense md-primary">
      <div class="md-toolbar-row">
        <div class="md-toolbar-section-start">
          <md-button class="md-icon-button" @click="menuVisible = !menuVisible">
            <md-icon>menu</md-icon>
          </md-button>

          <span class="md-title">Boss Hydraulik + Pneumatik</span>
        </div>
        <div class="md-toolbar-section-end">
          <md-field v-if="showMonthSelection && months">
            <label for="month">Monat</label>
            <md-select v-model="month" name="month" id="month">
              <md-option v-for="month in months" :key="month.date" :value="month.date">{{ month.month }}</md-option>
            </md-select>
          </md-field>
        </div>
      </div>
    </md-app-toolbar>

    <md-app-drawer :md-active.sync="menuVisible">
      <md-toolbar class="md-transparent" md-elevation="0">
        Navigation
      </md-toolbar>

      <md-list>
        <md-list-item @click="calendar">
          <md-icon>calendar_today</md-icon>
          <span class="md-list-item-text">Kalender</span>
        </md-list-item>

        <md-list-item @click="resources" v-if="isAdmin()">
          <md-icon>manage_accounts</md-icon>
          <span class="md-list-item-text">Mitarbeiter verwalten</span>
        </md-list-item>

        <md-list-item @click="account">
          <md-icon>key</md-icon>
          <span class="md-list-item-text">Passwort ändern</span>
        </md-list-item>

        <md-list-item @click="logout">
          <md-icon>logout</md-icon>
          <span class="md-list-item-text">Logout</span>
        </md-list-item>
      </md-list>
    </md-app-drawer>

    <md-app-content id="content">
      <router-view/>
    </md-app-content>
  </md-app>
</template>

<script>
function pad(str, max) {
  str = str.toString()
  return str.length < max ? pad('0' + str, max) : str
}

const monthLangs = []
monthLangs[0] = 'Januar'
monthLangs[1] = 'Februar'
monthLangs[2] = 'März'
monthLangs[3] = 'April'
monthLangs[4] = 'Mai'
monthLangs[5] = 'Juni'
monthLangs[6] = 'Juli'
monthLangs[7] = 'August'
monthLangs[8] = 'September'
monthLangs[9] = 'Oktober'
monthLangs[10] = 'November'
monthLangs[11] = 'Dezember'


let months = []

const d = new Date()
let month = d.getMonth()
let year = d.getFullYear()

for (let i = month + 1; i <= 12; i++) {
  months.push({date: year + '-' + pad(i, 2) + '-01', month: monthLangs[i - 1] + ' ' + year})
}
year++
for (let i = 1; i <= 12; i++) {
  months.push({date: year + '-' + pad(i, 2) + '-01', month: monthLangs[i - 1] + ' ' + year})
}

export default {
  name: 'AppLayout',
  data() {
    return {
      menuVisible: false,
      months: months,
      month: null
    }
  },
  methods: {
    async logout() {
      await this.$router.push({name: 'logout'})
      this.menuVisible = false
    },
    async calendar() {
      await this.$router.push({name: 'home'})
      this.menuVisible = false
    },
    async resources() {
      await this.$router.push({name: 'resources'})
      this.menuVisible = false
    },
    async account() {
      await this.$router.push({name: 'account'})
      this.menuVisible = false
    },
    isAdmin() {
      return localStorage.getItem('role') === 'admin'
    }
  },
  computed: {
    showMonthSelection() {
      return this.$route.name === 'home'
    }
  },
  watch: {
    month: async (val) => {
      location.href = '/?date=' + val
    }
  }
}
</script>
