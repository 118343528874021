<template>
  <div>
    <form novalidate class="md-layout" @submit.prevent="validateResource">
      <md-card class="md-layout-item md-size-50 md-small-size-100">
        <md-card-header>
          <div class="md-title" v-if="!id">Mitarbeiter anlegen</div>
          <div class="md-title" v-if="id">Mitarbeiter bearbeiten</div>
        </md-card-header>

        <md-card-content>
          <md-field :class="getValidationClass('name')">
            <label for="name">Name</label>
            <md-input name="name" id="name" autocomplete="given-name" v-model="form.name" :disabled="sending"/>
            <span class="md-error" v-if="!$v.form.name.required">Name ist erforderlich.</span>
            <span class="md-error" v-else-if="!$v.form.name.minlength">Ungültiger Name.</span>
          </md-field>
          <md-field :class="getValidationClass('role')">
            <label for="role">Job</label>
            <md-input name="role" id="role" v-model="form.role" :disabled="sending"/>
            <span class="md-error" v-if="!$v.form.role.required">Job ist erforderlich.</span>
          </md-field>
          <md-field :class="getValidationClass('eventColor')">
            <label for="eventColor">Farbe</label>
            <md-input name="eventColor" id="eventColor" v-model="form.eventColor" :disabled="sending"/>
            <span class="md-error" v-if="!$v.form.eventColor.required">Job ist erforderlich.</span>
          </md-field>
        </md-card-content>

        <md-progress-bar md-mode="indeterminate" v-if="sending"/>

        <md-card-actions>
          <md-button type="submit" class="md-primary" :disabled="sending" v-if="!id">Mitarbeiter anlegen</md-button>
          <md-button type="submit" class="md-primary" :disabled="sending" v-if="id">Mitarbeiter speichern</md-button>
        </md-card-actions>
      </md-card>

      <md-snackbar :md-active.sync="resourceSaved">Der Mitarbeiter {{ lastResource }} wurde erfolgreich gespeichert!
      </md-snackbar>
    </form>
  </div>
</template>

<script>
import {validationMixin} from 'vuelidate'
import {minLength, required} from 'vuelidate/lib/validators'
import ApiService from '@/service/api'

export default {
  name: 'ResourceForm',
  mixins: [validationMixin],
  data: () => ({
    id: null,
    form: {
      name: null,
      role: null,
      eventColor: '#ff0000'
    },
    resourceSaved: false,
    sending: false,
    lastResource: null
  }),
  validations: {
    form: {
      name: {
        required,
        minLength: minLength(3)
      },
      role: {
        required
      },
      eventColor: {
        required
      }
    }
  },
  async created() {
    if (!this.$route.params.id) {
      return;
    }

    this.id = parseInt(this.$route.params.id)
    let resources = await ApiService.get('/resources')
    let resource = resources.filter(r => r.id === this.id).shift()
    this.form.name = resource.name
    this.form.role = resource.role
    this.form.eventColor = resource.eventColor
  },
  watch: {
    '$route.params': function (newVal) {
      this.id = newVal.id ? newVal.id : 0
      // @todo: use success message somewhere also after adding a new one
      this.successMessage = newVal.successMessage ? newVal.successMessage : ''
    }
  },
  methods: {
    getValidationClass(fieldName) {
      const field = this.$v.form[fieldName]

      if (field) {
        return {
          'md-invalid': field.$invalid && field.$dirty
        }
      }
    },
    clearForm() {
      this.$v.$reset()
      this.form.name = null
      this.form.role = null
      this.form.eventColor = null
    },
    async saveResource() {
      this.sending = true

      if (!this.id) {
        await ApiService.post('resources', {
          data: [{
            name: this.form.name,
            role: this.form.role,
            eventColor: this.form.eventColor
          }]
        })
      } else {
        await ApiService.post('resources/update', {
          data: [{
            id: this.id,
            name: this.form.name,
            role: this.form.role,
            eventColor: this.form.eventColor
          }]
        })
      }
      this.lastResource = `${this.form.name}`
      this.resourceSaved = true
      this.sending = false
      this.clearForm()
      await this.$router.push({name: 'resources'})
    },
    validateResource() {
      this.$v.$touch()

      if (!this.$v.$invalid) {
        this.saveResource()
      }
    }
  }
}
</script>
