<template>
  <div>
    <bryntum-scheduler v-if="schedulerConfig"
                       v-bind="schedulerConfig"
    ></bryntum-scheduler>
  </div>
</template>

<script>
import BryntumScheduler from '@bryntum/scheduler-vue'
import {
  DateHelper,
  EventModel,
  EventStore,
  LocaleManager,
  ResourceStore,
  StringHelper,
  ViewPreset
} from '@bryntum/scheduler'
import '@/locales'
import ApiService from '@/service/api'

const RELOAD_INTERVAL = 10000;

class MyEvent extends EventModel {
  static get fields() {
    return [
      {name: 'eventType', type: 'number', defaultValue: 1},
      {name: 'description', type: 'string'}
    ]
  }
}

export default {
  name: 'Home',
  components: {
    BryntumScheduler
  },
  data() {
    return {
      schedulerConfig: null,
      loaded: 0,
      minDate: null,
      maxDate: null,
      pauseLoading: false
    }
  },
  methods: {
    loadData() {
      if (this.pauseLoading) {
        return;
      }

      this.eventStore.load({
        startDate: DateHelper.format(this.minDate, 'YYYY-MM-DD'),
        endDate: DateHelper.format(this.maxDate, 'YYYY-MM-DD')
      })
    }
  },
  async beforeMount() {
    const resourceStore = new ResourceStore({
      createUrl: process.env.VUE_APP_API_URL + '/resources',
      readUrl: process.env.VUE_APP_API_URL + '/resources',
      updateUrl: process.env.VUE_APP_API_URL + '/resources/update',
      deleteUrl: process.env.VUE_APP_API_URL + '/resources/delete',
      autoCommit: true,
      autoLoad: true,
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    })

    const that = this
    this.eventStore = new EventStore({
      createUrl: process.env.VUE_APP_API_URL + '/events',
      readUrl: process.env.VUE_APP_API_URL + '/events',
      updateUrl: process.env.VUE_APP_API_URL + '/events/update',
      deleteUrl: process.env.VUE_APP_API_URL + '/events/delete',
      autoCommit: true,
      singleAssignment: true,
      keepUncommittedChanges: true,
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      },
      modelClass: MyEvent,
      listeners: {
        change(e) {
          if (e.action === 'add' && isNaN(e.records[0].id)) {
            that.pauseLoading = true
          }

          if (e.action === 'remove' || (e.action === 'update' && e.changes.id !== undefined)) {
            that.pauseLoading = false
          }
        },
        loadDateRange(e) {
          if (!that.minDate || e.new.startDate < that.minDate) {
            that.minDate = e.new.startDate
          }
          if (!that.maxDate || e.new.endDate > that.maxDate) {
            that.maxDate = e.new.endDate
          }
          e.source.load({
            startDate: DateHelper.format(e.new.startDate, 'YYYY-MM-DD'),
            endDate: DateHelper.format(e.new.endDate, 'YYYY-MM-DD')
          })
        }
      }
    })

    let eventTypes = await ApiService.get('/eventTypes')
    let eventTypesSelection = []
    eventTypes.forEach(function (eventType) {
      eventTypesSelection.push({value: eventType.id, text: eventType.name})
    })

    LocaleManager.throwOnMissingLocale = true
    LocaleManager.applyLocale('De')
    const myViewPreset = new ViewPreset({
      id: 'myPreset',              // Unique id value provided to recognize your view preset. Not required, but having it you can simply set new view preset by id: scheduler.viewPreset = 'myPreset'

      tickWidth: 200,                // Time column width in horizontal mode
      displayDateFormat: 'HH:mm',    // Controls how dates will be displayed in tooltips etc

      shiftIncrement: 1,             // Controls how much time to skip when calling shiftNext and shiftPrevious.
      shiftUnit: 'day',         // Valid values are 'millisecond', 'second', 'minute', 'hour', 'day', 'week', 'month', 'quarter', 'year'.
      defaultSpan: 24,            // By default, if no end date is supplied to a view it will show 12 hours

      timeResolution: {              // Dates will be snapped to this resolution
        unit: 'hours'
      },

      headers: [                     // This defines your header rows from top to bottom
        {
          unit: 'week',
          dateFormat: 'W',
          renderer: (startDate) => {
            return 'KW ' + DateHelper.format(startDate, 'W')
          }
        },
        {
          unit: 'day',
          dateFormat: 'dd'
        },
        {
          unit: 'day',
          dateFormat: 'D.MMM YY'
        }
      ],

      columnLinesFor: 1              // Defines header level column lines will be drawn for. Defaults to the last level.
    })

    let currentDate = new Date()
    if (this.$route.query.date) {
      currentDate = new Date(this.$route.query.date)
    }
    currentDate.setHours(0)
    currentDate.setMinutes(0)
    currentDate.setSeconds(0)

    let schedulerConfig = {
      columns: [
        {
          type: 'resourceInfo',
          text: 'Staff',
          width: '10em',
          enableCellContextMenu: false,
          showEventCount: false,
          editor: false
        }
      ],

      infiniteScroll: true,

      eventStore: this.eventStore,
      resourceStore: resourceStore,

      visibleDate: {
        date: DateHelper.set(currentDate),
        block: 'start',
        edgeOffset: 0
      },
      weekStartDay: 1,
      enableRecurringEvents: true,
      stripe: true,
      fillTicks: true,
      viewPreset: myViewPreset,

      scheduleTooltipFeature: false,
      // eventDragFeature: {
      //   showTooltip: false
      // },
      // eventDragCreateFeature: {
      //   showTooltip: false
      // },
      // eventResizeFeature: {
      //   showTooltip: false
      // },
      eventEditFeature: {
        items: {
          resourceField: {hidden: true},

          eventType: {
            type: 'combo',
            label: 'Typ',
            name: 'eventType',
            editable: false,
            weight: 10,
            items: eventTypesSelection,
            value: '1'
          },

          description: {
            type: 'textarea',
            label: 'Beschreibung',
            name: 'description',
            weight: 101
          }
        }
      },
      nonWorkingTimeFeature: true,

      eventRenderer({eventRecord}) {
        return [{
          html: DateHelper.format(eventRecord.startDate, 'LT') + ' - ' + DateHelper.format(eventRecord.endDate, 'LT')
        }, {
          html: StringHelper.encodeHtml(eventRecord.name)
        }, {
          html: StringHelper.encodeHtml(eventRecord.description)
        }]
      }
    }

    if (localStorage.getItem('role') !== 'admin') {
      schedulerConfig.eventEditFeature = false;
      schedulerConfig.eventDragCreateFeature = false;
      schedulerConfig.eventResizeFeature = false;
      schedulerConfig.eventDragFeature = false;
      schedulerConfig.readOnly = true;
    }

    this.schedulerConfig = schedulerConfig

    setInterval(this.loadData, RELOAD_INTERVAL)
  }
}
</script>

<style lang="scss">
@import "~@bryntum/scheduler/scheduler.material.min.css";

.b-sch-event-content {
  font-weight: 500;
  flex-direction: column;
}

.b-sch-event-content :first-child {
  font-size: 0.8em;
  font-weight: 300;
}

.b-sch-event-wrap {
  min-height: 40px !important;
  height: auto !important;
}

.b-sch-style-dashed div {
  text-align: center;
}

.b-vue-scheduler-container {
  flex-direction: column;
  height: calc(100vh - 96px) !important;
}

.b-sch-event {
  color: #000 !important;
}
</style>
