import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/Home'
import Login from '@/views/Login'
import PublicLayout from '@/layouts/PublicLayout'
import AppLayout from '@/layouts/AppLayout'
import Resources from '@/views/Resources'
import ResourceForm from '@/views/resources/ResourceForm'
import AccountForm from '@/views/AccountForm'

Vue.use(VueRouter)

const routes = [
    {
        path: '/login',
        component: PublicLayout,
        meta: {guest: true},
        children: [
            {
                path: '',
                name: 'login',
                component: Login
            }
        ]
    },
    {
        path: '/',
        component: AppLayout,
        meta: {requiresAuth: true},
        children: [
            {
                path: '',
                name: 'home',
                component: Home
            },
            {
                path: '/resources',
                name: 'resources',
                component: Resources,
                meta: {admin: true}
            },
            {
                path: '/resources/add',
                name: 'resource-add',
                component: ResourceForm,
                meta: {admin: true}
            },
            {
                path: '/resources/edit/:id',
                name: 'resource-edit',
                component: ResourceForm,
                meta: {admin: true}
            },
            {
                path: '/account',
                name: 'account',
                component: AccountForm,
                meta: {admin: true}
            }
        ]
    },
    {
        path: '/logout',
        name: 'logout',
        component: {
            beforeRouteEnter(to, from, next) {
                localStorage.removeItem('token')
                next({name: 'login'})
            }
        },
        meta: {requiresAuth: true}
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (localStorage.getItem('token') === null) {
            next({
                name: 'login',
                params: {nextUrl: to.fullPath}
            })
        }
    } else if (to.matched.some(record => record.meta.guest)) {
        if (localStorage.getItem('token') !== null) {
            next({name: 'home'})
        }
    } else if (to.matched.some(record => record.meta.admin)) {
        if (localStorage.getItem('role') !== 'admin') {
            next({name: 'home'})
        }
    }
    next()
})

export default router
