<template>
  <div>
    <form novalidate class="md-layout" @submit.prevent="validatePassword">
      <md-card class="md-layout-item md-size-50 md-small-size-100">
        <md-card-header>
          <div class="md-title">Passwort ändern</div>
        </md-card-header>

        <md-card-content>
          <md-field :class="getValidationClass('password')">
            <label for="password">Passwort</label>
            <md-input name="password" id="password" v-model="form.password" :disabled="sending"/>
            <span class="md-error" v-if="!$v.form.password.required">Passwort ist erforderlich.</span>
            <span class="md-error"
                  v-else-if="!$v.form.password.minlength">Passwort muss mindestens 6 Zeichen lang sein.</span>
          </md-field>
          <md-field :class="getValidationClass('passwordConfirmation')">
            <label for="passwordConfirmation">Passwort wiederholen</label>
            <md-input name="passwordConfirmation" id="passwordConfirmation" v-model="form.passwordConfirmation"
                      :disabled="sending"/>
            <span class="md-error"
                  v-if="!$v.form.passwordConfirmation.required">Passwort Wiederholung erforderlich.</span>
            <span class="md-error" v-else-if="!$v.form.passwordConfirmation.sameAsPassword">Passwort Wiederholung ist nicht dieselbe wie Passwort.</span>
          </md-field>
        </md-card-content>

        <md-progress-bar md-mode="indeterminate" v-if="sending"/>

        <md-card-actions>
          <md-button type="submit" class="md-primary" :disabled="sending">Passwort ändern</md-button>
        </md-card-actions>
      </md-card>

      <md-snackbar :md-active.sync="passwordSaved">Das Passwort wurde erfolgreich geändert!
      </md-snackbar>
    </form>
  </div>
</template>

<script>
import {validationMixin} from 'vuelidate'
import {minLength, required, sameAs} from 'vuelidate/lib/validators'
import ApiService from '@/service/api'

export default {
  name: 'ResourceForm',
  mixins: [validationMixin],
  data: () => ({
    id: null,
    form: {
      password: null,
      passwordConfirmation: null
    },
    passwordSaved: false,
    sending: false
  }),
  validations: {
    form: {
      password: {
        required,
        minLength: minLength(6)
      },
      passwordConfirmation: {
        required,
        sameAsPassword: sameAs('password')
      }
    }
  },
  watch: {
    '$route.params': function (newVal) {
      this.id = newVal.id ? newVal.id : 0
      // @todo: use success message somewhere also after adding a new one
      this.successMessage = newVal.successMessage ? newVal.successMessage : ''
    }
  },
  methods: {
    getValidationClass(fieldName) {
      const field = this.$v.form[fieldName]

      if (field) {
        return {
          'md-invalid': field.$invalid && field.$dirty
        }
      }
    },
    clearForm() {
      this.$v.$reset()
      this.form.password = null
      this.form.passwordConfirmation = null
    },
    async savePassword() {
      this.sending = true

      await ApiService.post('account', {
        password: this.form.password,
        passwordConfirmation: this.form.passwordConfirmation
      })
      this.passwordSaved = true
      this.sending = false
      this.clearForm()
    },
    validatePassword() {
      this.$v.$touch()

      if (!this.$v.$invalid) {
        this.savePassword()
      }
    }
  }
}
</script>
