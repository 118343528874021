<template>
  <div>
    <md-table v-model="resources" md-card>
      <md-table-toolbar>
        <div class="md-toolbar-section-start">
          <h1 class="md-title">Mitarbeiter</h1>
        </div>

        <div class="md-toolbar-section-end">
          <md-button class="md-icon-button" @click="$router.push({name: 'resource-add'})">
            <md-icon>add</md-icon>
          </md-button>
        </div>
      </md-table-toolbar>

      <md-table-row slot="md-table-row" slot-scope="{ item }" @click="$router.push({name: 'resource-edit', params: {id: item.id}})">
        <md-table-cell md-label="Name" md-sort-by="name"><md-avatar class="md-small" :style="badgeStyle(item)">{{ item.name.slice(0, 1) }}</md-avatar>&nbsp;&nbsp;{{ item.name }}</md-table-cell>
        <md-table-cell md-label="Krankheitstage" md-sort-by="illDays">
          <div v-for="(value, year) in item.illDays" :key="year">
            {{ year }}: {{ value }}
          </div>
        </md-table-cell>
        <md-table-cell md-label="Ferientage" md-sort-by="vacationDays">
          <div v-for="(value, year) in item.vacationDays" :key="year">
            {{ year }}: {{ value }}
          </div>
        </md-table-cell>
        <md-table-cell md-label="Überzeit" md-sort-by="overTimeHours">
          <div v-for="(value, year) in item.overTimeHours" :key="year">
            {{ year }}: {{ value }}
          </div>
        </md-table-cell>
      </md-table-row>
    </md-table>
  </div>
</template>

<script>
import '@/locales'
import ApiService from '@/service/api'

export default {
  name: 'Resources',
  data() {
    return {
      resources: []
    }
  },
  async beforeMount() {
    this.resources = await ApiService.get('/resources')
  },
  methods: {
    badgeStyle(item) {
      return {
        "background-color": item.eventColor,
        "color": "white"
      }
    }
  }
}
</script>
